export default {
  auth: {
    title: 'Practical and <br/>Easy',
    subtitle: 'internet connectivity',
  },
  login: {
    dologin: 'Login',
    dologintext: 'To login please enter <b>username</b> and password.',
    doforgot: 'Forgot Password',
    doforgottext: 'To recover your passwprd <b>please enter your email address</b> so we can send you a reset link via email.',
    recoverpassword: 'New Password',
    recoverpasswordtext: 'Fill in the blanks for the new password.',
    registerhere: 'Register Here.',
    inputusername: 'Username...',
    inputemail: 'Email address...',
    inputnewpassword: 'New password...',
    inputnewpassword2: 'Re-enter new password...',
    buttonlogin: 'Login >>',
    rememberme: 'Remember Me',
    forgotpassword: 'Forgot password?',
    sendlink: 'Receive Recover Link',
    forgotmessage: 'Recover link has been sent to your email address.',
    expired_token: 'Recover link has been expired.',
    approve: 'Approve',
    changepasswordmessage: 'Password has been changed successfully.',
  },
  register: {
    title: 'Create New User',
    subtitle: 'Please fill in the information below to register. Enter a valid mobile phone number as confirmation will be made by SMS.',
    input_name: 'Name',
    input_lastname: 'Surname',
    input_email: 'E-Mail',
    input_phone: 'Phone Number',
    input_company: 'Company Name',
    input_website: 'Web Site',
    input_sector: 'Sector',
    input_selectsector: 'Choose a Sector',
    button_register: 'Register',
  },
  navigation: {
    infopage: 'Dashboard',
    cockpit: 'Cockpit',
    sitemap: 'Site Map',
    auth: 'Authentication',
    captiveportal: 'Captive Portals',
    usertemplate: 'User Templates',
    devices: 'Devices',
    userinfo: 'User Management',
    userinfo_all: 'All Users',
    userinfo_registered: 'Registered Users',
    userinfo_online: 'Online Users',
    userinfo_adduser: 'Add New User',
    userinfo_registration_request: 'Registration Requests',
    ticket_code: 'Ticket Code',
    settings: 'Settings',
    settings_sms_services: 'SMS Services',
    settings_pms_services: 'Database Services',
    settings_mailer_services: 'Mail Services',
    settings_provider_logs: 'Service Logs',
    profile: 'Profile',
    logout: 'Logout',
    // surveys: 'Surveys',
    // ads: 'Ads',
  },
  home: {
    box_1: {
      title: 'Cockpit',
      content: 'Detailed info about your system.',
    },
    box_2: {
      title: 'CAPTIVE PORTALS',
      content: 'Customize guest captive portal pages.',
    },
    box_3: {
      title: 'USER MANAGEMENT',
      content: 'Manage your user configurations.',
    },
    box_4: {
      title: 'DOCUMENT',
      content: 'You can access the documents for installation and other operations here.',
    },
    box_5: {
      title: 'ADS / PROMOTION',
      content: 'Prepare and share ads to your guests.',
    },
    box_6: {
      title: 'SETTINGS',
      content: 'General system configurations.',
    },
  },
  captiveportals: {
    list: {
      title: 'CAPTIVE PORTALS',
      createcaptiveportals: 'CREATE NEW CAPTIVE PORTAL',
      preview: 'PREVIEW',
    },
    listbox: {
      edit: 'Edit',
      show: 'Show',
      delete: 'Delete',
      activate: 'ACTIVE',
      deactivate: 'ACTIVATE',
      deletemessage: 'Successfully deleted.',
      deleteerror: 'Error occured while deleting!',
    },
    edit: {
      edittitle: 'CAPTIVE PORTAL WIZARD',
      next: 'Next',
      CPCaptiveDesign: 'DESIGN',
      CPAuth: 'AUTH TYPE',
      CPAuthSettings: 'AUTH SETTINGS',
      CPLanguage: 'LANGUAGE SETTINGS',
      CPAds: 'ADS / PROMOTIONS',
      CPSetting: 'ADVANCED SETTINGS',
      CPSave: 'FINISH AND SAVE',
    },
    steps: {
      design: {
        titles: {
          form: 'FORM AREA',
          button: 'BUTTON',
          position: 'POSITION',
          logo: 'LOGO',
          bg: 'BACKGROUND',
        },
        stepform: {
          title: 'FORM SETTINGS',
          labelfontsize: 'Label Font Size',
          labelfontcolor: 'Label Font Color',
          inputformsize: 'Input Font Size',
          inputfontcolor: 'Input Font Color',
          inputbgcolor: 'Input Background Color',
          inputborderrad: 'Input Border Radius',
          inputborder: 'Input Border',
          inputbordercolor: 'Input Border Color',
          inputpadding: 'Input Padding',
          inputmargin: 'Input Margin',
          formbackground: 'Form Area Background Color',
          formfontsize: 'Form Font Size',
          formfontcolor: 'Form Font Color',
          formborderrad: 'Form Border Radius',
          formborder: 'Form Border',
          formbordercolor: 'Form Border Color',
          formpadding: 'Form Padding',
          inputphcolor: 'Input PlaceHolder Color',
        },
        stepbutton: {
          title: 'BUTTON SETTINGS',
          type: 'Type',
          size: 'Font Size',
          color: 'Font Color',
          bgcolor: 'Background Color',
          borderrad: 'Border Radius',
          border: 'Border',
          bordercolor: 'Border Color',
          padding: 'Padding',
          margin: 'Margin',
        },
        stepposition: {
          title: 'POSITION SETTINGS',
          position: 'Position',
        },
        steplogo: {
          title: 'LOGO SETTINGS',
          logo: 'Logo',
        },
        stepbg: {
          title: 'BACKGROUND SETTINGS',
          color: 'Background Color',
          image: 'Background Image',
          position: 'Background Position',
          repeat: 'Repeat Type',
        },
      },
      auth: {
        titles: {
          authType: 'CHOOSE AUTHENTICATION TYPE',
          askedFields: 'CHOOSE ASKED FIELDS',
          add_new_field: 'ADD NEW FIELD',
        },
        table: {
          field_text: 'Text Field',
          field_type: 'Type',
          field_size: 'Size',
          field_req: 'Required',
          field_edit: 'Edit',
        },
        buttons: {
          add_new: 'ADD NEW',
        },
        new_field: {
          name: 'Field Name',
          minlen: 'Minimum Size',
          maxlen: 'Maxiumum Size',
          isrequired: 'Required',
          isactive: 'Status',
          type: 'Field Type',
          required: 'Required',
          optional: 'Optional',
          active: 'Active',
          passive: 'Passive',
          string: 'String',
          string_alpha: 'Alphabetical',
          integer: 'Integer',
          datetime: 'Date & Time',
        },
        go_back: '< BACK',
        save: 'SAVE',
        required: 'Required',
        optionally: 'Optional',
        authtype: {
          manual: 'Standart Authentication',
          turkish_citizenship: 'Turkish Citizenship',
          turkish_foreigncitizenship: 'Foreign Citizenship',
          turkish_hybridcitizenship: 'Hybrid Citizenship',
          adldap: 'AD/LDAP',
          pms: 'Database',
          none: 'YOK',
          sms: 'SMS Authentication',
          ticket_code: 'Ticket Code',
          sponsor_email: 'SPONSOR E-MAIL',
        },
        askedFields: {
          tc_id_number: 'TC Citizenship ID',
          name: 'Name',
          surname: 'Surname',
          birth_year: 'Birth Year',
          room_number: 'Room Number',
          identity: 'Passport Number',
          ad_username: 'AD/LDAP Username',
          ad_password: 'AD/LDAP Password',
          phone_number: 'Phone Number',
          phone_number_validation: 'Phone Number Validation',
          ticket_input: 'Ticket Code',
          full_name: 'Name and Surname',
          birth_date: 'Birth Date(year-month-day)',
          wr_username: 'Wirofy Username',
          wr_password: 'Wirofy Password',
          email: 'E-Mail',
        },
      },
      authsettings: {
        usercombination: 'Username Combination',
        usertemplate: 'User Template',
        smsprovider: 'SMS Provider',
        passwordstrength: 'User Password',
        smspasswordstrength: 'SMS Password Complexity',
        smspasswordexp: 'SMS Timeout',
        hasusercreate: 'Overwrite Users',
        smstype: 'SMS Saving Mode',
        pmsprovider: 'Database Provider',
        mailerprovider: 'Mail Provider',
        basic: 'Basic',
        medium: 'Medium',
        complex: 'Complex',
        macrememeber: 'Remember by MAC',
        rule: 'Rule',
        recipient_email: 'Recipient E-Mail',
      },
      language: {
        beforeadded: 'Already added.',
        addnew: 'Add New Language',
        select: 'Select',
        addlanguage: 'Add Language',
        add: 'Add',
        label: {
          general: 'General Language Settings',
          message: 'Messages',
          footer: 'Footer',
          loggingin: 'Login Success Message',
          loginfailed: 'Login Error Message',
          loginoption: 'Login Option: %s',
          loginoptiontitle: 'General Language Fields',
          loginoptioninput: 'Form Language Fields',
          login_title: 'Title',
          login_placeHolder: 'Placeholder',
          title: 'Captive Portal Title',
          optionselecttext: 'Header Title',
          provider: 'SMS & Database Integration Language Fields',
          smstext: 'SMS Text Template',
          eula: 'EULA Content',
          nda: 'NDA Content',
        },
      },
      advanced: {
        title: 'EULA & NDA SETTINGS',
        mandatory: 'Required',
        optional: 'Optional',
        disabled: 'Disabled',
        eula: 'EULA Status',
        nda: 'NDA Status',
        eula_text: 'EULA Text',
        nda_text: 'NDA Text',
        mac_remember: 'MAC Remember',
        mac_remember_timeout: 'MAC Remember Timeout (Hours)',
      },
      save: {
        title: 'FINISH AND SAVE',
        savemessage: 'Configuration has been saved sucessfully.',
        errormessage: 'Error occured while saving configuration!',
        cpname: 'Captive Portal Name',
        devicelisttitle: 'Choose Devices',
        savebutton: 'Save',
      },
    },
  },
  infopage: {
    badges: {
      'sms:crypttech': 'REMAINING SMS COUNT',
      today_sent_sms: 'TODAY SENT SMS COUNT',
      total_sent_sms: 'TOTAL SENT SMS COUNT',
      online_users: 'ACTIVE USERS',
      session: 'REMAINING SESSION CREDITS',
      total_registered_users: 'TOTAL REGISTERED USERS',
      buy_credits: 'BUY CREDITS',
    },
    devices: {
      my_devices: 'MY DEVICES(%s)',
      add_new_device: 'Add New',

    },
    licenses: {
      captive_portal: {
        title: 'CAPTIVE PORTALS',
        box_1: 'TOTAL',
        box_2: 'ACTIVE',
      },
      license_box: {
        title: 'LICENSE INFO',
        box_1: 'PACKAGE',
        box_2: 'REMAINING TIME',
        left_days: 'DAYS LATER',
      },
    },
    chart: {
      new_users: 'New Registrations',
    },
  },
  device: {
    form: {
      add_device: 'ADD NEW DEVICE',
      edit_device: 'EDIT DEVICE',
      detail_device: 'DEVICE DETAILS',
      name: 'Device Name',
      name_placeholder: 'Device Name...',
      brand: 'Brand',
      model: 'Model',
      login: 'Captive Portal',
      description: 'Description',
      description_placeholder: 'Description...',
      savemessage: 'Device has been successfully saved.',
      deletemessage: 'Device has been successfully deleted.',
      auto_create: 'Auto device create',
      setting: {
        mac: 'MAC',
        mac2: 'MAC(Other)',
        mac3: 'MAC(Other)',
        mac4: 'MAC(Other)',
        ufi: 'UFI / Serial Number',
        sn: 'Serial Number',
        mgmtBaseUrl: 'mgmtBaseUrl(Optional)',
        unifiServer: 'Unifi Server URL',
        user: 'User',
        password: 'Password',
      },
      error: {
        undefineddevice: 'Device not found!',
        invalid_data: 'Please check the form fields!',
        device_limit_error: 'License device limit has been reached!',
      },
    },
    mikrotik: {
      info_message: 'The default login directory might be changed on some RouterOS devices. In such case, changing the "hotspot/login.html" field in the code changes to "flash/hotspot/login.html".',
    },
    unifi_guest: {
      info_message: 'The files in the directory specified in your operating system must be replaced.\n/var/lib/unifi/sites//portal/index.html (on most Linux servers such as Ubuntu)\n/usr/lib/unifi/sites//portal/index.html (on other Linux servers)\n%userprofile%/Ubiquiti Unifi/data/sites//portal/index.html (on Windows servers)\n/Applications/UniFi.app/Contents/Resources/sites//portal/index.html (on Macs)\n/srv/unifi/data/sites//portal/index.html (on UniFi Cloud Keys)',
    },
  },
  userinfo: {
    activeusers: 'ACTIVE USERS',
    registeredusers: 'REGISTERED USERS',
    statistic: 'STATISTICS',
    list_title: 'User List',
    types: {
      total: 'TOTAL',
      facebook: 'FACEBOOK',
      twitter: 'TWITTER',
      pms: 'DATABASE',
      sms: 'SMS',
      turkish_citizenship: 'TC CITIZENSHIP',
      turkish_hybridcitizenship: 'TC HYBRID CITIZENSHIP',
      turkish_foreigncitizenship: 'TC FOREIGN CITIZENSHIP',
      turkish_othercitizenship: 'TC FOREIGN CITIZENSHIP',
      ticket_code: 'TICKET CODE',
      manual: 'MANUAL',
      none: 'YOK',
      sponsor_email: 'SPONSOR E-MAIL',
    },
    buttons: {
      change_view: 'Change View',
      details: 'Detailed List',
    },
    form: {
      title: {
        add: 'Add New User',
        edit: 'Edit User',
      },
      tabletitlekeyword: 'Title-Type',
      tabletitlevalue: 'Value',
      savemessage: 'User has been saved successfully.',
      deletemessage: 'User has been deleted successfully.',
      error: {
        keywordselecterror: 'Already added!',
        keywordselectempty: 'Please choose Title-Type',
        keywordvalueempty: 'Please enter valid Meta Value',
        unavailable_username: 'Username has been already taken!',
        invalid_username: 'Invalid username',
        invalid_password: 'Invalid password',
        error_usercreate: 'Error on user create!',
        username_notfound: 'Username not found!',
      },
      username: 'Username',
      password: 'Password',
      user_template: 'User Template',
      miltiple_label: 'Format: CSV Example: username,password,user template id',
    },
    list: {
      username: 'Username',
      mac: 'MAC',
      platform: 'Platform',
      browser: 'Browser',
      nda_eula: 'NDA / EULA',
      register_date: 'Registeration Date',
      login_date: 'Last Login',
      download: 'Download',
      upload: 'Upload',
      connection_start: 'Connection Started',
      connection_end: 'Connection Ended',
      session_time: 'Session Time',
      device: 'Device',
      ip: 'IP',
      session_time_second: '%s Seconds',
      auth_types: 'Auth Types',
      nda: 'NDA',
      eula: 'EULA',
      status: 'Status',
      update_date: 'User Update',
      all: 'All User',
      online: 'Online User',
      is_online: 'Online User',
      registration_status: 'Registration Status',
      registration_type: {
        0: 'Waiting for Response',
        1: 'Approved',
        2: 'Denied',
      },
    },
    chart: {
      gender: 'GENDER',
      age: 'AGE',
      browser: 'BROWSER',
      platform: 'PLATFORM',
      agerange: 'AGE RANGE',
      yearsandolder: 'YEARS AND OLDER',
    },
    detail: {
      usage_title: 'Usage Details',
      list_day: 'Last %s Days',
      login_log_title: 'Login Logs',
      usage_log_title: 'Usage Logs',
    },
  },
  usertemplate: {
    list_title: 'User Template List',
    list: {
      name: 'Name',
    },
    form: {
      title: 'User Template Form',
      name: 'Name',
      idletimeout: 'Idle Timeout(Seconds)',
      sessiontimeout: 'Session Timeout(Seconds)',
      hasredirect: 'User Redirect Status',
      hasredirect_deviceurl: 'Device Redirect Address',
      hasredirect_seturl: 'Specified URL',
      redirecturl: 'Specified URL',
      savemessage: 'Successfully saved.',
      deletemessage: 'User Template has been removed.',
      simultaneoususage: 'Simultaneous Usage Limit',
      hassimultaneous: 'Simultaneous Usage Status',
      allow: 'Allow',
      disallow: 'Deny',
      expiration: 'Expiration',
      none: 'None',
      expiration_date: 'Expiration Date',
      auto_set_create: 'From User Creation Date',
      auto_set_first_login: 'From User First Login',
      expiration_minute: 'Expiration Time(Minute)',
      error: {
        undefineduser: 'User template not found!',
        invalid_data: 'Please check the form fields!',
      },
    },
  },
  ticketcode: {
    list_title: 'Ticket Code List',
    list: {
      code: 'Code',
      usage_limit: 'Usage Limit',
      expiration_at: 'Expiration Date',
      created_at: 'Created At',
      status: 'Status',
      add_multiplebutton: 'Generate Bulk Ticket Codes',
    },
    form: {
      title: {
        add: 'New Ticket Code',
        edit: 'Update Ticket Code',
      },
      code: 'Ticket Code',
      status: 'Status',
      usage_limit: 'Usage Limit',
      expiration_at: 'Expiration Date',
      count: 'Count',
      passwordstrength: 'Complexity',
      savemessage: 'Sucessfully Saved',
      deletemessage: 'Ticket Code has been removed.',
      basic: 'Basic',
      medium: 'Medium',
      complex: 'Complex',
      error: {
        undefinedticketcode: 'Ticket code not found!',
        invalid_data: 'Error! Please check fields.',
        eixting_code: 'Please enter different ticket code.',
      },
    },
  },
  provider: {
    logs: {
      list_title: 'Provider Logs',
      list: {
        type: 'Type',
        message: 'Message',
        recipient: 'Recipient',
        created_at: 'Date',
      },
    },
    list_title: {
      sms: 'SMS Providers List',
      pms: 'Database Providers List',
      mailer: 'Mail Providers List',
    },
    list: {
      name: 'Provider Name',
      provider: 'Provider',
      created_at: 'Created At',
      balance: 'Balance',
      balance_query: 'Remaining usage limit: ',
      check_pms: 'Connection Check',
      check_false: 'Connection test failed!',
      check_true: 'Connection test successful.',
      check_other: 'Connection test result: ',
    },
    error: {
      error_balance: 'The operation cannot be performed at this time. Please try again later.',
    },
    form: {
      title: {
        pmsadd: 'Database Provider Adding Form',
        pmsedit: 'Database Provider Updating Form',
        smsadd: 'SMS Provider Adding Form',
        smsedit: 'SMS Provider Updating Form',
        maileradd: 'MAIL Provider Adding Form',
        maileredit: 'MAIL Provider Updating Form',
      },
      name: 'Service Name',
      provider: 'Provider',
      savemessage: 'Successfully Saved',
      deletemessage: 'Provider Deleted',
      error: {
        undefinedprovider: 'Provider Not Found!',
      },
      API_USERNAME: 'API Username',
      API_PASSWORD: 'API Password',
      API_ORIGINATOR: 'API Originator',
      API_ID: 'API ID',
      API_SENDER: 'API Sender',
      API_FROM: 'API Form',
      API_COMPANY: 'API Company',
      API_COMPANYID: 'API Company',
      API_CHANEL: 'API Channel',
      API_CHANNEL: 'API Channel',
      API_VENDORID: 'API Vendor Id',
      API_OUTBOX: 'API Outbox',
      API_KEY: 'API Key',
      HTTP_PROXY: 'Proxy',
      API_BAYIKODU: 'Agent Code',
      MESSAGE_HEADER: 'SMS Header',
      OTP_STATUS: 'OTP Status',
      SERVICE_CODE: 'Service Code',
      ALPHA_NUMERIC: 'Alphanumeric',
      API_ALPHANUMERIC: 'API Alphanumeric',
      CUSTOMER_NO: 'Customer No',
      API_USERNO: 'API USER NO',
      SQL_HOST: 'Sql Host',
      SQL_USER: 'Sql Username',
      SQL_PASSWORD: 'Sql Password',
      SQL_DATABASE: 'Sql Database',
      SQL_TABLE: 'Sql Table Name',
      FTP_URL: 'FTP Address',
      FTP_USER: 'FTP Username',
      FTP_PASSWORD: 'FTP Password',
      HOTEL_NUMBER: 'Room Number',
      SMTP_SECURITY: 'SMTP Security',
      SMTP_HOST: 'SMTP Server Host',
      SMTP_PORT: 'SMTP Server Port',
      SMTP_USERNAME: 'SMTP Server Username',
      SMTP_PASSWORD: 'SMTP Server Password',
      SMTP_SENDER: 'SMTP Server Sender Address',
    },
  },

  profile: {
    title: 'Profile',
    username: 'Username',
    email: 'E-Mail',
    first_name: 'First Name',
    last_name: 'Last Name',
    oldpassword: 'Old Password',
    password: 'Password',
    repassword: 'Re-password',
    savemessage: 'Profile saved',
    error: {
      invalid_password: 'Password and Re-password is not match!',
      invalid_user: 'User not found!',
      invalid_oldpassword: 'In order to change your password, you must enter your old password correctly.',
      duplicate_email: 'The email address is used by another user. Please enter a different email address.',
      invalid_notify_settings: 'Notification settings not found.',
    },
    notificationtypes: 'Notification Types',
    thresholds: 'Thresholds',
    notifyviaemail: 'Notify via email.',
    notifyviapanel: 'Notify via panel.',
    smsthreshold: 'SMS Threshold',
    sessionthreshold: 'Session Threshold',
  },
  upload: {
    loading: 'Uploading. Please wait...',
    error: 'File upload error!',
    save_image: 'Image has been uploaded successfully.',
    save_file: 'File has been uploaded successfully.',
    ext_error: 'Invalid Extention',
  },
  alert: {
    danger: 'Danger!',
    info: 'Info!',
    success: 'Success',
    warning: 'Warning',
  },
  error: {
    plugin_method_not_avaliable: 'Extension method not found for defined control type.',
    plugin_not_avaliable: 'No defined control type!',
    is_required: 'Required field! Please do not leave blank.',
    cannot_be_null: 'Invalid Data Type! This field cannot have a NULL value.',
    number_max_exceed: 'Enter a smaller number.',
    number_min_exceed: 'Enter a larger number.',
    length_max_exceed: 'You have exceeded the number of characters!',
    length_min_exceed: 'Insufficient Number of Characters',
    invalid_regexp: 'This field does not match.',
    invalid_int: 'This field can only be a number.',
    invalid_double: 'This field can only receive numeric or decimal data.',
    invalid_email: 'Invalid email address',
    invalid_boolean: 'Invalid data type',
    invalid_url: 'Invalid URL',
    invalid_domain: 'Invalid domain address',
    invalid_ip: 'Invalid IP address',
    invalid_ipv4: 'Invalid IP address. Must only be of type ipv4.',
    invalid_ipv6: 'Invalid IP address. Must only be of type ipv6.',
    invalid_mac: 'Invalid MAC address',
    invalid_weak_password: 'Invalid password. Your password must contain at least one uppercase letter, a lowercase letter, a number, and a special character.',
    invalid_date: 'Invalid data. This field can only be date.',
    invalid_option: 'Invalid data type',
    error_maxlength: 'Max lenght has been exceeded!',
    error_minlength: 'Unsufficent Character Count',
    error_required: 'Required Field',
    error_invalidemail: 'Invalid Mail Address',
    error_weakpassword: 'Weak Password',
    error_isnumber: 'Only numeric characters allowed',
    error_minnumber: 'This field must be in range %s. Please enter greater number.',
    error_maxnumber: 'This field must be in range %s. Please enter less number.',
    error_match: 'This field does not match.',
    error_invalidurl: 'Invalid Url',
    error_invalidalpha: 'Invalid Alphabetical String',
    error_invalidmac: 'Invalid MAC address',
    error_invalidip: 'Invalid IP address',
    error_invaliddomain: 'Invalid domain address',
    invalid_user: 'User not found.',
    invalid_username_password: 'Invalid username or password!',
    invalid_password: 'Invalid password',
    usernamecombnotselected: 'Username combination is required.',
    templatenotselected: 'User template is required.',
    authtypenotselected: 'Auth type is required.',
    error_license_pms: 'Upgrade license to use database integration.',
    invalid_oldpassword: 'In order to change your password, you must enter your old password correctly.',
    token_expired: 'Request expired.',
    license_expired: 'License has been expired.',
    smsprovidernotselected: 'SMS Provider is required.',
    smspasswordexpinvalid: 'SMS Timeout is required.',
    mailerprovidernotselected: 'Mailer Provider is required.',
    recipientrulesnotselected: 'At least one rule must be added to the recipient.',
    device: {
      device_limit_error: 'You have reached the device limit!',
    },
  },
  general: {
    selectall: 'Select All',
    select: 'Select',
    on: 'On',
    off: 'Off',
    active: 'Active',
    deactive: 'Passive',
    successful: 'Success',
    unsuccessful: 'Unsuccess',
    approve: 'Approve',
    approved: 'Approved',
    reject: 'Reject',
    disapproved: 'Disapproved',
    deleteconfirm: 'Please confirm delete.',
    save: 'Save',
    edit: 'Edit',
    detail: 'Details',
    delete: 'Delete',
    next: 'Next',
    prev: 'Previous',
    first: 'First',
    last: 'Last',
    all: 'All',
    apply_filter: 'Filter',
    back: 'Back',
    selectplaceholder: 'Select...',
    choosefile: 'Choose file...',
    male: 'MALE',
    female: 'FEMALE',
    other: 'OTHER',
    unknown: 'UNKNOWN',
    none: 'NONE',
    pagination: {
      info: 'Between %s entries, a total of %s-%s records are listed.',
      nodata: 'No data found.',
    },
    advanced_filter: 'Advanced Filter',
    ssl: 'SSL',
    tls: 'TLS',
  },
  notification: {
    title: 'Notifications',
    warning: {
      left_sms: 'SMS usage limit has reached the threshold value.',
      left_session: 'Session usage limit has reached the threshold value.',
    },
    danger: {
      left_sms: 'Remaining SMS usage limits at critical levels.',
      left_session: 'Remaining session usage limits at critical levels.',
    },
  },
};
