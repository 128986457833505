import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router, Route, Redirect, Switch,
} from 'react-router-dom';
import { Chart, registerables } from "chart.js";
import './master.css';
import Loading from "../../Components/Loading/Loading";

Chart.register(...registerables);

const Home = lazy(() => import('../../Components/Home/Home'));
const Navigation = lazy(() => import('../../Components/Navigation/Navigation'));
const InfoPageLayout = lazy(() => import('../InfoPageLayout/InfoPageLayout'));
const MyDevices = lazy(() => import('../DevicesLayout/MyDevices'));
const UserTemplateLayout = lazy(() => import('../UserTemplateLayout/UserTemplateLayout'));
const ProviderLayout = lazy(() => import('../ProviderLayout/ProviderLayout'));
const TicketCodeLayout = lazy(() => import('../TicketCodeLayout/TicketCodeLayout'));
const ProfileLayout = lazy(() => import('../ProfileLayout/ProfileLayout'));
const Logout = lazy(() => import('../../Components/Logout/Logout'));
const AutoLogin = lazy(() => import('../../Components/Login/AutoLogin'));
const UserInfoLayout = lazy(() => import('../UserInfoLayout/UserInfoLayout'));
const CaptivePortalsLayout = lazy(() => import('../CaptivePortalsLayout/CaptivePortalsLayout'));

const Master = () => (
  <Suspense fallback={<Loading status={false} />}>
    <Router>
      <div className="master-layout">
        <Navigation />
        <div className="master-content">
          <div className="container-fluid">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/info-page" component={InfoPageLayout} />
              <Route path="/my-devices" component={MyDevices} />
              <Route path="/user-template*" component={UserTemplateLayout} />
              <Route path="/provider*" component={ProviderLayout} />
              <Route path="/ticket-code*" component={TicketCodeLayout} />
              <Route path="/user-info*" component={UserInfoLayout} />
              <Route exact path="/captive-portal*" component={CaptivePortalsLayout} />
              <Route exact path="/profile" component={ProfileLayout} />
              <Route exact path="/logout" component={Logout} />
              <Route path="/auto_login/:hash" component={AutoLogin} />
              <Route path="/autologin/:hash" component={AutoLogin} />
              <Route path="/login" render={() => (<Redirect to="/home" />)} />
              <Route exact path="*" render={() => (<Redirect to="/" />)} />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  </Suspense>
);

export default Master;
