// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/images/BG.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App,
#root {
    position: relative;
}

.master-layout {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100vh;
    /*overflow-y: scroll;overflow-y: auto;*/
}

.master-content {
    width: calc(100% - 20px);
    min-height: calc(100vh - 90px);
    background-color: #e0e0e0;
    margin: 75px 10px 15px 10px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/MasterLayout/master.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;IACV,iBAAiB;IACjB,uCAAuC;AAC3C;;AAEA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,yBAAyB;IACzB,2BAA2B;IAC3B,iFAAyE;IACzE,8BAA8B;IAC9B,2BAA2B;IAC3B,yBAAyB;IACzB,sBAAsB;AAC1B","sourcesContent":[".App,\n#root {\n    position: relative;\n}\n\n.master-layout {\n    position: relative;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    max-height: 100vh;\n    /*overflow-y: scroll;overflow-y: auto;*/\n}\n\n.master-content {\n    width: calc(100% - 20px);\n    min-height: calc(100vh - 90px);\n    background-color: #e0e0e0;\n    margin: 75px 10px 15px 10px;\n    background: url(../../Assets/images/BG.svg) no-repeat center center fixed;\n    -webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
