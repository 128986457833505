// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wr-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.wr-loading .wr-loading-content {
    width: 187px;
    margin: 0 auto;
    margin-top: 45vh;
    font-size: 24px;
    color: #fff;
    font-family: AvenirNext-Bold;
}

.wr-loading .wr-loading-content img {
    width: 187px;
    height: 150px;
}

.wr-loading.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.wr-loading.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.5s linear;
}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,gBAAgB;IAChB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,gDAAgD;AACpD;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,gDAAgD;AACpD","sourcesContent":[".wr-loading {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.9);\n    z-index: 9999999;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n}\n\n.wr-loading .wr-loading-content {\n    width: 187px;\n    margin: 0 auto;\n    margin-top: 45vh;\n    font-size: 24px;\n    color: #fff;\n    font-family: AvenirNext-Bold;\n}\n\n.wr-loading .wr-loading-content img {\n    width: 187px;\n    height: 150px;\n}\n\n.wr-loading.hide {\n    visibility: hidden;\n    opacity: 0;\n    transition: visibility 0.5s, opacity 0.5s linear;\n}\n\n.wr-loading.show {\n    visibility: visible;\n    opacity: 1;\n    transition: visibility 0.5s, opacity 0.5s linear;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
