/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import TranslateService from '../../Services/TranslateService';
import './alert.css';

const Alert = inject('CommonStore')(
  observer(({ CommonStore }) => {
    const [autoDismiss, setAutoDismiss] = useState(5);
    const [reloadView, setReloadView] = useState(true);
    useEffect(() => {
      // console.log('view mountedsss');
      // console.log(AuthStore.getToken());
    }, []);

    const deleteAlert = (i) => {
      delete CommonStore.errors[i];
      setAutoDismiss(autoDismiss);
      setReloadView(!reloadView);
    };

    const defaultView = (data, i) => {
      if ((typeof data.autoDismiss !== 'undefined' && data.autoDismiss !== false) && data.check === false) {
        data.autoDismiss = (data.autoDismiss === true) ? autoDismiss : data.autoDismiss;
        CommonStore.errors[i].check = true;
        setTimeout(() => {
          deleteAlert(i);
        }, data.autoDismiss * 1000);
      }
      return (
        <div key={i} className={`default-alert ${data.level}`}>
          <div className="default-alert-head" onClick={() => deleteAlert(i)}>
            <div className="text-left"><span>{(typeof data.title === 'undefined') ? TranslateService.getText(`alert.${data.level}`) : data.title}</span></div>
            <div className="text-right"><i className="fa fa-times" /></div>
          </div>
          <div className="default-alert-content">{data.message}</div>
        </div>
      );
    };
    return (
      <div className="wr-alert">
        {
          CommonStore.errors.map((e, i) => ((typeof e.type === 'undefined' || e.type === 'default') ? defaultView(e, i) : ''))
        }
      </div>
    );
  }),
);
export default Alert;
