// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wr-alert {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

.default-alert {
    position: relative;
    right: 40px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom:20px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.7);
}

.default-alert:first-child{
    margin-top:90px;
}

.default-alert .default-alert-head {
    width: 100%;
    padding: 5px 15px;
    font-family: AvenirNext-Bold;
    font-size: 14px;
    color: #fff;
    background-color: #ccc;
    min-width: 350px;
}

.default-alert .default-alert-head>div {
    display: inline;
}

.default-alert .default-alert-head .text-right {
    float: right;
    cursor: pointer;
}

.default-alert .default-alert-content {
    width: 100%;
    padding: 5px 15px;
    font-size: 12px;
    color: #000;
    text-align: left;
}

.default-alert.danger .default-alert-head{
    background-color: #bf2d56;
}
.default-alert.success .default-alert-head{
    background-color: #52c700;
}
.default-alert.info .default-alert-head{
    background-color: #31708f;
}
.default-alert.warning .default-alert-head{
    background-color: #ffd703;
}`, "",{"version":3,"sources":["webpack://./src/Components/Alert/alert.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,MAAM;IACN,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,4BAA4B;IAC5B,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".wr-alert {\n    position: fixed;\n    right: 0;\n    top: 0;\n    z-index: 99999;\n}\n\n.default-alert {\n    position: relative;\n    right: 40px;\n    background-color: #fff;\n    border-radius: 5px;\n    overflow: hidden;\n    margin-bottom:20px;\n    box-shadow: 0px 0px 5px rgba(0,0,0,0.7);\n}\n\n.default-alert:first-child{\n    margin-top:90px;\n}\n\n.default-alert .default-alert-head {\n    width: 100%;\n    padding: 5px 15px;\n    font-family: AvenirNext-Bold;\n    font-size: 14px;\n    color: #fff;\n    background-color: #ccc;\n    min-width: 350px;\n}\n\n.default-alert .default-alert-head>div {\n    display: inline;\n}\n\n.default-alert .default-alert-head .text-right {\n    float: right;\n    cursor: pointer;\n}\n\n.default-alert .default-alert-content {\n    width: 100%;\n    padding: 5px 15px;\n    font-size: 12px;\n    color: #000;\n    text-align: left;\n}\n\n.default-alert.danger .default-alert-head{\n    background-color: #bf2d56;\n}\n.default-alert.success .default-alert-head{\n    background-color: #52c700;\n}\n.default-alert.info .default-alert-head{\n    background-color: #31708f;\n}\n.default-alert.warning .default-alert-head{\n    background-color: #ffd703;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
