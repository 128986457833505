/* eslint-disable class-methods-use-this */
import Parser from 'html-react-parser';
import tr from './i18n/tr';
import en from './i18n/en';
import CommonStore from '../Stores/CommonStore';

class TranslateService {
  getText(text, parser = true) {
    let nText = text;
    this.Language = CommonStore.appLanguage;
    if (this.Language === 'tr') {
      nText = this.value(tr, nText);
    } else if (this.Language === 'en') {
      nText = this.value(en, nText);
    }
    if (parser) {
      nText = Parser(nText);
    }
    return nText;
  }

  getTextWithReplace(text, ...params) {
    let nText = text;
    nText = this.getText(nText, false);
    for (let i = 0; i < params.length; i += 1) {
      nText = nText.replace('%s', params[i]);
    }
    nText = Parser(nText);
    return nText;
  }

  value(layer, path) {
    let nLayer = layer;
    const spath = path.split('.');
    for (let i = 0; i < spath.length; i += 1) {
      if (typeof nLayer[spath[i]] === 'undefined') {
        // console.log(`There is no match in keys: ${path}`);
        return path;
      }
      nLayer = nLayer[spath[i]];
    }
    return nLayer;
  }
}

export default new TranslateService();
