import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import App from './App';
import AuthStore from './Stores/AuthStore';
import CommonStore from './Stores/CommonStore';
import CaptivePortalStore from './Stores/CaptivePortalStore';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider AuthStore={AuthStore} CommonStore={CommonStore} CaptivePortalStore={CaptivePortalStore}>
    <App />
  </Provider>
);
