/* eslint-disable class-methods-use-this */
import { action, makeObservable, observable } from 'mobx';

class CommonStore {
  appName = 'Wirofy';

  appLanguage = null;

  errors = [];

  loading = {};

  commonDataMap = {
    updateDeviceList: true,
    userList: [],
    userTemplateList: [],
  };

  notificationCount = 0;

  constructor() {
    makeObservable(this, {
      appName: observable,
      appLanguage: observable,
      errors: observable,
      loading: observable,
      commonDataMap: observable,
      notificationCount: observable,
      changeLanguage: action,
      setLoading: action,
      getLanguage: action,
    });
    this.appLanguage = this.getLanguage();
  }

  changeLanguage(appLanguage) {
    this.appLanguage = appLanguage;
  }

  async setLoading(key, val) {
    this.loading[key] = val;
  }

  getLanguage() {
    return 'en';
    /*
    const slang = window.localStorage.getItem('language');
    if (slang === null) {
      let lang = '';
      const language = navigator.language || navigator.userLanguage;
      if (language === 'tr') {
        lang = 'tr';
      } else if (language.indexOf('tr-') !== -1) {
        lang = 'tr';
      } else if (language === 'en') {
        lang = 'en';
      } else if (language.indexOf('en-') !== -1) {
        lang = 'en';
      } else {
        lang = 'en';
      }
      window.localStorage.setItem('language', lang);
      return lang;
    }
    return slang; */
  }
}

export default new CommonStore();
