/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Alert from './Components/Alert/Alert';
import Loading from './Components/Loading/Loading';
import Auth from './Layouts/AuthLayout/Auth';
import Master from './Layouts/MasterLayout/Master';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Assets/css/style.css';
import './Assets/css/responsive.css';

const App = inject('AuthStore', 'CommonStore')(
  observer(({ AuthStore, CommonStore }) => {
    useEffect(() => {
      // console.log('view mountedsss');
      // console.log(AuthStore.getToken());
    }, []);

    let Layout = '';
    if (AuthStore.isLogged) {
      Layout = <Master />;
    } else {
      Layout = <Auth />;
    }

    let loadingstatus = true;
    if (Object.keys(CommonStore.loading).filter(
      (x) => CommonStore.loading[x],
    ).length > 0) {
      loadingstatus = false;
    }

    // console.log(loadingstatus);

    return (
      <div className="App">
        {Layout}
        <Alert />
        <Loading status={loadingstatus} />
        <div className="wr-version-code">
          VER:
          {' '}
          {
            // eslint-disable-next-line no-process-env
            process.env.VERSION
          }
        </div>
      </div>
    );
  }),
);
export default App;
