// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-right{
    background-color: #BE205A;
    height: 100vh;
}

.login-left{
    height: 100vh;
    padding-left: 70px;
    padding-right: 70px;
}

.auth-logo{
    width:100%;
}
.auth-logo img{
    height:30%;
}
.auth-logo h1{
    font-family: AvenirNext-Bold;
    font-size:26px;
    margin-bottom:0;

}
.auth-logo h2{
    font-family: AvenirNext-UltraLight;
    font-size: 22px;
    margin-bottom:0;
}






/*login-right*/
.btn-secondary{
    color: #BE205A!important;
    background-color: white!important;
    font-weight: 800!important;
    border-color: white!important;
    margin-top: 20px!important;
    padding: 5px 30px!important;
}
.btn-secondary:hover{
    background-color:#BE205A!important;
    color: white!important;
    border-color: white!important;
}
.btn-secondary.outline{
    margin-right: 8px!important;
    background-color: #BE205A!important;
    color: white!important;
}
.btn-secondary.outline:hover{
    background-color: white!important;
    color: #BE205A!important;
}

.main-image{
    position: absolute;
    max-height: 500px;
    max-width: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/AuthLayout/auth.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,4BAA4B;IAC5B,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,kCAAkC;IAClC,eAAe;IACf,eAAe;AACnB;;;;;;;AAOA,cAAc;AACd;IACI,wBAAwB;IACxB,iCAAiC;IACjC,0BAA0B;IAC1B,6BAA6B;IAC7B,0BAA0B;IAC1B,2BAA2B;AAC/B;AACA;IACI,kCAAkC;IAClC,sBAAsB;IACtB,6BAA6B;AACjC;AACA;IACI,2BAA2B;IAC3B,mCAAmC;IACnC,sBAAsB;AAC1B;AACA;IACI,iCAAiC;IACjC,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,QAAQ;IACR,2BAA2B;IAC3B,iBAAiB;IACjB,kBAAkB;IAClB,OAAO;IACP,QAAQ;AACZ","sourcesContent":[".login-right{\n    background-color: #BE205A;\n    height: 100vh;\n}\n\n.login-left{\n    height: 100vh;\n    padding-left: 70px;\n    padding-right: 70px;\n}\n\n.auth-logo{\n    width:100%;\n}\n.auth-logo img{\n    height:30%;\n}\n.auth-logo h1{\n    font-family: AvenirNext-Bold;\n    font-size:26px;\n    margin-bottom:0;\n\n}\n.auth-logo h2{\n    font-family: AvenirNext-UltraLight;\n    font-size: 22px;\n    margin-bottom:0;\n}\n\n\n\n\n\n\n/*login-right*/\n.btn-secondary{\n    color: #BE205A!important;\n    background-color: white!important;\n    font-weight: 800!important;\n    border-color: white!important;\n    margin-top: 20px!important;\n    padding: 5px 30px!important;\n}\n.btn-secondary:hover{\n    background-color:#BE205A!important;\n    color: white!important;\n    border-color: white!important;\n}\n.btn-secondary.outline{\n    margin-right: 8px!important;\n    background-color: #BE205A!important;\n    color: white!important;\n}\n.btn-secondary.outline:hover{\n    background-color: white!important;\n    color: #BE205A!important;\n}\n\n.main-image{\n    position: absolute;\n    max-height: 500px;\n    max-width: 100%;\n    top: 50%;\n    transform: translateY(-50%);\n    margin-left: auto;\n    margin-right: auto;\n    left: 0;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
