export default {
  auth: {
    title: 'Pratik ve <br/>Kolay',
    subtitle: 'internet hizmeti',
  },
  login: {
    dologin: 'Giriş Yapınız',
    dologintext: 'Giriş yapmak için <b>kullanıcı adınızı</b> ve parolanızı giriniz.',
    doforgot: 'Parolamı Unuttum',
    doforgottext: 'Parolanızı sıfırlamak için <b>mail adresinizi giriniz</b> böylelikle size sıfırlama maili gönderebiliriz.',
    recoverpassword: 'Yeni Parola',
    recoverpasswordtext: 'Yeni parolanızı oluşturmak için lütfen aşağıdaki bilgileri doldurunuz.',
    registerhere: 'Kayıt Olunuz.',
    inputusername: 'Kullanıcı adınız...',
    inputemail: 'Email adresiniz...',
    inputnewpassword: 'Yeni parolanız...',
    inputnewpassword2: 'Yeni parolanızı tekrar giriniz...',
    buttonlogin: 'Giriş Yap >>',
    rememberme: 'Beni Hatırla',
    forgotpassword: 'Parolamı Unuttum',
    sendlink: 'Sıfırlama Maili Al',
    forgotmessage: 'Sıfırlama linki mail adresinize gönderildi.',
    expired_token: 'Sıfırlama linki süresi doldu.',
    approve: 'Onayla',
    changepasswordmessage: 'Parola değiştirildi.',
  },
  register: {
    title: 'Yeni Kayıt Oluştur',
    subtitle: 'Kayıt olmak için lütfen aşağıdaki bilgileri doldurunuz. Doğrulama SMS ile yapılacağından lütfen geçerli bir mobil telefon numarası giriniz.',
    input_name: 'Adınız',
    input_lastname: 'Soyadınız',
    input_email: 'E-Posta Adresiniz',
    input_phone: 'Telefon Numarası',
    input_company: 'Firma Adı',
    input_website: 'Web Sitesi',
    input_sector: 'Sektör',
    input_selectsector: 'Bir Sektör Seçiniz',
    button_register: 'Kayıt Ol',
  },
  navigation: {
    infopage: 'Göstergeler',
    cockpit: 'Kokpit',
    sitemap: 'Site Haritası',
    auth: 'Yetkilendirme',
    captiveportal: 'Karşılama Sayfaları',
    usertemplate: 'Kullanıcı Şablonu',
    devices: 'Cihazlar',
    userinfo: 'Kullanıcılar',
    userinfo_all: 'Tüm Kullanıcılar',
    userinfo_registered: 'Kayıtlı Kullanıcılar',
    userinfo_online: 'Online Kullanıcılar',
    userinfo_adduser: 'Kullanıcı Ekle',
    userinfo_registration_request: 'Onay Bekleyen Kullanıcılar',
    ticket_code: 'Bilet Kodu',
    settings: 'Ayarlar',
    settings_sms_services: 'SMS Servisleri',
    settings_pms_services: 'Veritabanı Servisleri',
    settings_mailer_services: 'Mail Servisleri',
    settings_provider_logs: 'Servis Logları',
    profile: 'Profil',
    logout: 'Çıkış Yap',
    // surveys: 'Anketler',
    // ads: 'Reklamlar'
  },
  home: {
    box_1: {
      title: 'KOKPİT',
      content: 'Bu ekranda sisteminiz ile ilgili bilgileri takip edebilirsiniz.',
    },
    box_2: {
      title: 'KARŞILAMA SAYFALARI',
      content: 'Misafirlerinizi karşılayacağınız ekranları buradan düzenleyebilirsiniz.',
    },
    box_3: {
      title: 'KULLANICILAR',
      content: 'Kullanıcı ayarlarınızı buradan yönetebilirsiniz.',
    },
    box_4: {
      title: 'DÖKÜMAN',
      content: 'Kurulum ve diğer işlemler için dökümana buradna erişebilirsiniz.',
    },
    box_5: {
      title: 'REKLAM / PROMOSYON',
      content: 'Misafirlarinize göstermek istediğiniz reklamları buradan yönetebilirsiniz.',
    },
    box_6: {
      title: 'AYARLAR',
      content: 'Sisteminizin tüm ayarlarınızı bu bölümden gerçekleştirebilirsiniz.',
    },
  },
  captiveportals: {
    list: {
      title: 'KARŞILAMA EKRANLARI',
      createcaptiveportals: 'KARŞILAMA EKRANI OLUŞTUR',
      preview: 'ÖNİZLEME',
    },
    listbox: {
      edit: 'Düzenle',
      show: 'Göster',
      delete: 'Sil',
      activate: 'AKTİF',
      deactivate: 'AKTİF ET',
      deletemessage: 'İstenilen veri silindi.',
      deleteerror: 'Silinirken hata oluştu!',
    },
    edit: {
      edittitle: 'KARŞILAMA EKRANI OLUŞTURMA SİHİRBAZI',
      next: 'Devam',
      CPCaptiveDesign: 'EKRAN TASARIMI',
      CPAuth: 'KİMLİK DOĞRULAMA',
      CPAuthSettings: 'KİMLİK DOĞRULAMA AYARLARI',
      CPLanguage: 'DİL VE GİRİŞ AYARLARI',
      CPAds: 'REKLAM / ANKET AYARLARI',
      CPSetting: 'GELİŞMİŞ AYARLAR',
      CPSave: 'BİTİR VE KAYDET',
    },
    steps: {
      design: {
        titles: {
          form: 'FORM ALANI',
          button: 'BUTON',
          position: 'YERLEŞİM',
          logo: 'LOGO',
          bg: 'ZEMİN',
        },
        stepform: {
          title: 'FORM AYARLARI',
          labelfontsize: 'Label Font Boyut',
          labelfontcolor: 'Label Font Renk',
          inputformsize: 'Input Font Boyut',
          inputfontcolor: 'Input Font Renk',
          inputbgcolor: 'Input Arkaplan Renk',
          inputborderrad: 'Input Oval Köşe',
          inputborder: 'Input Kenar(Border)',
          inputbordercolor: 'Input Kenar Renk',
          inputpadding: 'Input İç Boşluk',
          inputmargin: 'Input Dış Boşluk',
          formbackground: 'Form Alanı Arkaplan Renk',
          formfontsize: 'Form Font Boyut',
          formfontcolor: 'Form Font Renk',
          formborderrad: 'Form Oval Köşe',
          formborder: 'Form Kenar(Border)',
          formbordercolor: 'Form Kenar Renk',
          formpadding: 'Form İç Boşluk',
          inputphcolor: 'Input PlaceHolder Renk',
        },
        stepbutton: {
          title: 'BUTON AYARLARI',
          type: 'Tip',
          size: 'Font Boyut',
          color: 'Font Renk',
          bgcolor: 'Arkaplan Renk',
          borderrad: 'Oval Köşe',
          border: 'Kenar(Border)',
          bordercolor: 'Kenar Renk',
          padding: 'İç Boşluk',
          margin: 'Dış Boşluk',
        },
        stepposition: {
          title: 'YERLEŞİM AYARLARI',
          position: 'Yerleşim',
        },
        steplogo: {
          title: 'LOGO AYARLARI',
          logo: 'Logo',
        },
        stepbg: {
          title: 'ZEMİN AYARLARI',
          color: 'Zemin Rengi',
          image: 'Zemin Görseli',
          position: 'Görsel Yerleşimi',
          repeat: 'Görsel Tekrarı',
        },
      },
      auth: {
        titles: {
          authType: 'DOĞRULAMA YÖNTEMİNİ SEÇİNİZ',
          askedFields: 'SORULACAK ALANLARI SEÇİNİZ',
          add_new_field: 'YENİ ALAN EKLE',
        },
        table: {
          field_text: 'Soru Metni',
          field_type: 'Tipi',
          field_size: 'Boyutu',
          field_req: 'Zorunluluk',
          field_edit: 'Düzenleme',
        },
        buttons: {
          add_new: 'YENİ EKLE',
        },
        new_field: {
          name: 'Alanın Adı',
          minlen: 'Minimum Boyut',
          maxlen: 'Maksimum Boyut',
          isrequired: 'Zorunluluk',
          isactive: 'Durum',
          type: 'Alanın Tipi',
          required: 'Zorunlu',
          optional: 'Opsiyonel',
          active: 'Aktif',
          passive: 'Pasif',
          string: 'Metin',
          string_alpha: 'Alfabetik',
          integer: 'Sayısal',
          datetime: 'Tarih ve Saat',
        },
        go_back: 'GERİ DÖN',
        save: 'KAYDET',
        required: 'Zorunlu',
        optionally: 'Opsiyonel',
        authtype: {
          manual: 'Standart Üyelik',
          turkish_citizenship: 'TC Kimlik',
          turkish_foreigncitizenship: 'Yabancı Vatandaşlık',
          turkish_hybridcitizenship: 'TC Hibrit Vatandaşlık',
          adldap: 'AD/LDAP',
          pms: 'Veritabanı',
          none: 'YOK',
          sms: 'SMS Doğrulama',
          ticket_code: 'Bilet Kodu',
          sponsor_email: 'SPONSOR E-MAIL',
        },
        askedFields: {
          tc_id_number: 'TC Kimlik Numarası',
          name: 'Adı',
          surname: 'Soyadı',
          birth_year: 'Doğum Yılı',
          room_number: 'Oda Numarası',
          identity: 'Pasaport Numarası',
          ad_username: 'AD/LDAP Kullanıcı Adı',
          ad_password: 'AD/LDAP Parola',
          phone_number: 'Telefon Numarası',
          phone_number_validation: 'Telefon Numarası Doğrulama Alanı',
          ticket_input: 'Bilet Kodu Alanı',
          full_name: 'Ad ve Soyad',
          birth_date: 'Doğum Tarihi(yıl-ay-gün)',
          wr_username: 'Wirofy Kullanıcı Adı',
          wr_password: 'Wirofy Kullanıcı Parolası',
          email: 'E-Mail',
        },
      },
      authsettings: {
        usercombination: 'Kullanıcı Adı Kombinasyonu',
        usertemplate: 'Kullanıcı Şablonu',
        smsprovider: 'SMS Sağlayıcı',
        passwordstrength: 'Kullanıcı Parolası',
        smspasswordstrength: 'SMS Parolası',
        smspasswordexp: 'SMS Parola Süresi',
        hasusercreate: 'Yeni Üye Kaydı',
        smstype: 'SMS Tasarruf Modu',
        pmsprovider: 'Veritabanı Servisi',
        mailerprovider: 'Mail Servisi',
        basic: 'Basit',
        medium: 'Orta',
        complex: 'Karmaşık',
        macrememeber: 'Otomatik giriş(MAC)',
        rule: 'Kural',
        recipient_email: 'Alıcı E-Mail',
      },
      language: {
        beforeadded: 'Daha önce eklenmiştir.',
        addnew: 'Yeni Dil Ekle',
        select: 'Seçiniz',
        addlanguage: 'Dil Ekle',
        add: 'Ekle',
        label: {
          general: 'Genel dil alanları',
          message: 'Mesajlar',
          footer: 'Footer alanı',
          loggingin: 'Giriş yapıldı mesajı',
          loginfailed: 'Giriş hatası mesajı',
          loginoption: 'Giriş Sayfası: %s',
          loginoptiontitle: 'Genel Dil Alanları',
          loginoptioninput: 'Form İçin Dil Alanları',
          login_title: 'Başlık',
          login_placeHolder: 'Placeholder özelliği',
          title: 'Giriş Sayfası Başlığı',
          optionselecttext: 'Üst Yazı Alanı',
          provider: 'SMS & Veritabanı Entegrasyonu Dil Alanları',
          smstext: 'SMS Gönderim Şablonu',
          eula: 'Kullanım Sözleşmesi İçeriği',
          nda: 'Gizlilik Sözleşmesi İçeriği',
        },
      },
      advanced: {
        title: 'SÖZLEŞME AYARLARI',
        mandatory: 'Zorunlu',
        optional: 'Opsiyonel',
        disabled: 'Devre Dışı',
        eula: 'Kullanım Sözleşmesi Durumu',
        nda: 'Gizlilik Sözleşmesi Durumu',
        eula_text: 'Kullanım Sözleşmesi İçeriği',
        nda_text: 'Gizlilik Sözleşmesi İçeriği',
        mac_remember: 'Otomatik Giriş(MAC)',
        mac_remember_timeout: 'Otomatik Giriş Hatırlama Süresi (Saat)',
      },
      save: {
        title: 'KAYDETME ALANI',
        savemessage: 'Ayarlar başarıyla kaydedildi.',
        errormessage: 'Güncellenirken hata oluştu!',
        cpname: 'Karşılama Ekranı Adı',
        devicelisttitle: 'Uygulanacak Cihazlar',
        savebutton: 'Kaydet',
      },
    },
  },
  infopage: {
    badges: {
      'sms:crypttech': 'KALAN SMS KULLANIMI',
      today_sent_sms: 'BUGÜN GÖNDERİLEN SMS',
      total_sent_sms: 'TOPLAM GÖNDERİLEN SMS',
      online_users: 'AKTİF KULLANICILAR',
      session: 'KALAN OTURUM KREDİSİ',
      total_registered_users: 'TÜM KAYITLAR',
      buy_credits: 'KREDİ SATIN AL',
    },
    devices: {
      my_devices: 'CİHAZLARIM(%s)',
      add_new_device: 'Yeni Ekle',

    },
    licenses: {
      captive_portal: {
        title: 'KARŞILAMA SAYFALARI',
        box_1: 'TOPLAM',
        box_2: 'AKTİF',
      },
      license_box: {
        title: 'LİSANS BİLGİLERİ',
        box_1: 'PAKET BİLGİLERİ',
        box_2: 'KALAN SÜRE',
        left_days: 'GÜN KALDI',
      },
    },
    chart: {
      new_users: 'Yeni Kayıt Sayısı',
    },
  },
  device: {
    form: {
      add_device: 'YENİ CİHAZ EKLEME',
      edit_device: 'CİHAZ DÜZENLEME',
      detail_device: 'CİHAZ DETAYLARI',
      name: 'Cihaz Adı',
      name_placeholder: 'Cihaz Adı...',
      brand: 'Marka',
      model: 'Model',
      login: 'Karşılama Sayfası',
      description: 'Tanım',
      description_placeholder: 'Tanım...',
      savemessage: 'Cihaz değişiklikleri başarıyla kaydedildi.',
      deletemessage: 'Cihaz başarıyla silindi.',
      auto_create: 'Otomatik Cihaz Oluşturma',
      setting: {
        mac: 'MAC',
        mac2: 'MAC(Diğer)',
        mac3: 'MAC(Diğer)',
        mac4: 'MAC(Diğer)',
        ufi: 'UFI / Seri Numarası',
        sn: 'Seri Numarası',
        mgmtBaseUrl: 'mgmtBaseUrl(Opsiyonel)',
        unifiServer: 'Unifi Server URL',
        user: 'Kullanıcı',
        password: 'Şifre',
      },
      error: {
        undefineddevice: 'Cihaz bulunamadı!',
        invalid_data: 'Lütfen alanları kontrol ediniz!',
        device_limit_error: 'Lisans cihaz sınırına ulaşıldı!',
      },
    },
    mikrotik: {
      info_message: 'Bazı RouterOS cihazlarda varsayılan login dizini değişebilmektedir. Böyle bir durumda aşağıdaki kodlar arasında yer alan "hotspot/login.html" alanının "flash/hotspot/login.html" olarak değişmesi gibi.',
    },
    unifi_guest: {
      info_message: 'İşletim sisteminize göre aşağıda belirlenen dizinde yer alan dosyaların değiştirilmesi gerekmektedir.\n/var/lib/unifi/sites//portal/index.html (on most Linux servers such as Ubuntu)\n/usr/lib/unifi/sites//portal/index.html (on other Linux servers)\n%userprofile%/Ubiquiti Unifi/data/sites//portal/index.html (on Windows servers)\n/Applications/UniFi.app/Contents/Resources/sites//portal/index.html (on Macs)\n/srv/unifi/data/sites//portal/index.html (on UniFi Cloud Keys)',
    },
  },
  userinfo: {
    activeusers: 'AKTİF KULLANICILAR',
    registeredusers: 'KAYITLI KULLANICILAR',
    statistic: 'İSTATİSTİK',
    list_title: 'Kullanıcı Listesi',
    types: {
      total: 'TOPLAM',
      facebook: 'FACEBOOK',
      twitter: 'TWITTER',
      pms: 'VERİTABANI',
      sms: 'SMS',
      turkish_citizenship: 'TC KİMLİK',
      turkish_hybridcitizenship: 'TC HİBRİT KİMLİK',
      turkish_foreigncitizenship: 'TC YABANCI KİMLİK',
      turkish_othercitizenship: 'Yabancı Vatandaşlık',
      ticket_code: 'BİLET KODU',
      manual: 'MANUEL',
      none: 'YOK',
      sponsor_email: 'SPONSOR E-MAIL',
    },
    buttons: {
      change_view: 'Görünümü Değiştir',
      details: 'Detaylı Liste',
    },
    form: {
      title: {
        add: 'Yeni Kullanıcı Ekle',
        edit: 'Kullanıcı Düzenle',
      },
      tabletitlekeyword: 'Başlık-Tip',
      tabletitlevalue: 'Değer',
      savemessage: 'Kullanıcı başarıyla kaydedildi.',
      deletemessage: 'Kullanıcı başarıyla silindi.',
      error: {
        keywordselecterror: 'Daha önce eklendi!',
        keywordselectempty: 'Lütfen Başlık-Tip seçiniz.',
        keywordvalueempty: 'Lütfen değer giriniz.',
        unavailable_username: 'Bu kullanıcı adı daha önce kullanılmış!',
        invalid_username: 'Hatalı kullanıcı adı',
        invalid_password: 'Hatalı parola',
        error_usercreate: 'Kaydedilirken bir sorun oluştu!',
        username_notfound: 'Kullanıcı bulunamadı!',
      },
      username: 'Kullanıcı Adı',
      password: 'Parola',
      user_template: 'Kullanıcı Şablonu',
      miltiple_label: 'Dosya Tipi: CSV Örnek: kullanıcı adı,Şifre,Kullanıcı Şablonu id',
    },
    list: {
      username: 'Kullanıcı Adı',
      mac: 'MAC',
      platform: 'Platform',
      browser: 'Tarayıcı',
      nda_eula: 'Gizlilik Sözleşmesi / Kullanım Sözleşmesi',
      register_date: 'Kayıt Tarihi',
      login_date: 'Son Giriş Tarihi',
      download: 'Download',
      upload: 'Upload',
      connection_start: 'Bağlantı Başlangıcı',
      connection_end: 'Bağlantı Bitişi',
      session_time: 'Süre',
      device: 'Cihaz',
      ip: 'Ip',
      session_time_second: '%s Saniye',
      auth_types: 'Kayıt Tipleri',
      nda: 'Gizlilik Sözleşmesi',
      eula: 'Kullanım Sözleşmesi',
      status: 'Durum',
      update_date: 'Kullanıcı Düzenleme Tarihi',
      all: 'Tüm Kullanıcılar',
      online: 'Online Kullanıcılar',
      is_online: 'Online Kullanıcılar',
      registration_status: 'Onay Durumu',
      registration_type: {
        0: 'Beklemede',
        1: 'Onaylandı',
        2: 'Reddedildi',
      },
    },
    chart: {
      gender: 'CİNSİYET',
      age: 'YAŞ',
      browser: 'TARAYICI',
      platform: 'PLATFORM',
      agerange: 'YAŞ ARALIĞI',
      yearsandolder: 'YAŞ VE ÜZERİ',
    },
    detail: {
      usage_title: 'Kullanım Detayları',
      list_day: 'Son %s Gün',
      login_log_title: 'Giriş Kayıtları',
      usage_log_title: 'Kullanım Kayıtları',
    },
  },
  usertemplate: {
    list_title: 'Kullanıcı Şablonu Listesi',
    list: {
      name: 'Adı',
    },
    form: {
      title: 'Kullanıcı Şablonu Formu',
      name: 'Adı',
      idletimeout: 'Boşta Kalma Zaman Aşımı(Saniye)',
      sessiontimeout: 'Oturum Zaman Aşımı(Saniye)',
      hasredirect: 'Adres Yönlendirme Durumu',
      hasredirect_deviceurl: 'Cihazın Yönlendirdiği Adres',
      hasredirect_seturl: 'Sabit Adres',
      redirecturl: 'Sabit Adres',
      savemessage: 'Başarıyla kaydedildi.',
      deletemessage: 'Kullanıcı şablonu Silindi.',
      simultaneoususage: 'Eşzamanlı Kullanım Limiti',
      hassimultaneous: 'Eşzamanlı Kullanım Durumu',
      allow: 'İzin Ver',
      disallow: 'İzin Verme',
      expiration: 'Oturum Süresi',
      none: 'Yok',
      expiration_date: 'Sonlanma Tarihi',
      auto_set_create: 'Kullanıcı Oluşturma Tarihinden İtibaren',
      auto_set_first_login: 'Kullanıcı İlk Girişinden İtibaren',
      expiration_minute: 'Sonlanma Zamanı(Dakika)',
      error: {
        undefineduser: 'Kullanıcı şablonu bulunamadı!',
        invalid_data: 'Lütfen alanları kontrol ediniz!',
      },
    },
  },
  ticketcode: {
    list_title: 'Bilet Kodu Listesi',
    list: {
      code: 'Doğrulama Kodu',
      usage_limit: 'Kullanım Adeti',
      expiration_at: 'Son Kullanım Tarihi',
      created_at: 'Oluşturma Tarihi',
      status: 'Durum',
      add_multiplebutton: 'Çoklu Bilet Kodu Oluşturma',
    },
    form: {
      title: {
        add: 'Bilet Kodu Ekleme Formu',
        edit: 'Bilet Kodu Düzenleme Formu',
      },
      code: 'Bilet Kodu',
      status: 'Durumu',
      usage_limit: 'Kullanım Limiti',
      expiration_at: 'Son Kullanım Zamanı',
      count: 'Oluşturma Adedi',
      passwordstrength: 'Karmaşıklık',
      savemessage: 'Başarıyla Kaydedildi',
      deletemessage: 'Bilet Kodu Silindi',
      basic: 'Basit',
      medium: 'Orta',
      complex: 'Karmaşık',
      error: {
        undefinedticketcode: 'Bilet kodu bulunamadı!',
        invalid_data: 'Hata! Lütfen alanların doğru olduğuna emin olunuz.',
        existing_code: 'Lütfen farklı bir bilet kodu giriniz.',
      },
    },
  },
  provider: {
    logs: {
      list_title: 'Servis Logları',
      list: {
        type: 'Tip',
        message: 'Mesaj',
        recipient: 'Alıcı',
        created_at: 'Gönderim Tarihi',
      },
    },
    list_title: {
      sms: 'SMS Servisleri Listesi',
      pms: 'Veritabanı Servisleri Listesi',
      mailer: 'Mail Servisleri Listesi',
    },
    list: {
      name: 'Servis Adı',
      provider: 'Servis',
      created_at: 'Oluşturma Tarihi',
      balance: 'Kullanım Detayı',
      balance_query: 'Kalan Kullanım Limitiniz: ',
      check_pms: 'Bağlantı Testi',
      check_false: 'Bağlantı testi başarısız!',
      check_true: 'Bağlantı testi başarılı',
      check_other: 'Bağlantı testi sonucu: ',
    },
    error: {
      error_balance: 'İşlem şu anda gerçekleştirilemiyor. Lütfen daha sonra tekrar deneyiniz.',
    },
    form: {
      title: {
        pmsadd: 'Veritabanı Servisi Ekleme Formu',
        pmsedit: 'Veritabanı Servisi Düzenleme Formu',
        smsadd: 'SMS Servisi Ekleme Formu',
        smsedit: 'SMS Servisi Düzenleme Formu',
        maileradd: 'Mail Servisi Ekleme Formu',
        maileredit: 'Mail Servisi Düzenleme Formu',
      },
      name: 'Servis Adı',
      provider: 'Servis',
      savemessage: 'Başarıyla kaydedildi.',
      deletemessage: 'Servis silindi.',
      error: {
        undefinedprovider: 'Servis bulunamadı!',
      },
      API_USERNAME: 'API Kullanıcı Adı',
      API_PASSWORD: 'API Parolası',
      API_ORIGINATOR: 'API Başlık (Originator)',
      API_ID: 'API ID',
      API_SENDER: 'API Sender',
      API_FROM: 'API Form',
      API_COMPANY: 'API Company',
      API_COMPANYID: 'API Company',
      API_CHANEL: 'API Channel',
      API_CHANNEL: 'API Channel',
      API_VENDORID: 'API Vendor Id',
      API_OUTBOX: 'API Outbox',
      API_KEY: 'API Key',
      HTTP_PROXY: 'Proxy Adresi',
      API_BAYIKODU: 'Bayi Kodu',
      MESSAGE_HEADER: 'SMS Başlığı',
      OTP_STATUS: 'OTP Gönderim',
      SERVICE_CODE: 'Servis Kodu',
      ALPHA_NUMERIC: 'Alfanümerik',
      API_ALPHANUMERIC: 'Alfanümerik',
      CUSTOMER_NO: 'Müşteri Numarası',
      API_USERNO: 'Müşteri Numarası',
      SQL_HOST: 'Sql Host',
      SQL_USER: 'Sql Kullanıcı',
      SQL_PASSWORD: 'Sql Kullanıcı Parolası',
      SQL_DATABASE: 'Sql Veritabanı',
      SQL_TABLE: 'Sql Tablo Adı',
      FTP_URL: 'FTP Adresi',
      FTP_USER: 'FTP Kullanıcı Adı',
      FTP_PASSWORD: 'FTP Parolası',
      HOTEL_NUMBER: 'Otel Numarası',
      SMTP_SECURITY: 'SMTP Güvenlik',
      SMTP_HOST: 'SMTP Host',
      SMTP_PORT: 'SMTP Port',
      SMTP_USERNAME: 'SMTP Kullanıcı',
      SMTP_PASSWORD: 'SMTP Şifre',
      SMTP_SENDER: 'SMTP Gönderici Adresi',
    },
  },

  profile: {
    title: 'Profiliniz',
    username: 'Kullanıcı Adı',
    email: 'E-Posta',
    first_name: 'Ad',
    last_name: 'Soyad',
    oldpassword: 'Eski Parola',
    password: 'Parola',
    repassword: 'Parola Tekrarı',
    savemessage: 'Profil bilgileriniz başarıyla kaydedildi.',
    error: {
      invalid_password: 'Parolalar eşleşmiyor!',
      invalid_user: 'Kullanıcı bulunamadı!',
      invalid_oldpassword: 'Parolanızı değiştirebilmek için eski parolanızı doğru bir şekilde girmeniz gerekmektedir.',
      duplicate_email: 'Email adresi başka bir kullanıcı tarafından kullanılmaktadır. Lütfen farklı bir email adresi giriniz.',
      invalid_notify_settings: 'Bilgilendirme ayarları bulunamadı.',
    },
    notificationtypes: 'Bildirim Tipleri',
    thresholds: 'Eşik Değerleri',
    notifyviaemail: 'Panel üzerinden bildirim almak istiyorum.',
    notifyviapanel: 'Email ile bildirim almak istiyorum.',
    smsthreshold: 'SMS Eşik Değeri',
    sessionthreshold: 'Kullanım Süresi Eşik Değeri',
  },
  upload: {
    loading: 'Yükleniyor, lütfen bekleyiniz...',
    error: 'Dosya yüklemesi başarısız oldu!',
    save_image: 'Resim başarılı bir şekilde yüklendi.',
    save_file: 'Dosya başarılı bir şekilde yüklendi.',
    ext_error: 'Geçersiz Dosya Uzantısı',
  },
  alert: {
    danger: 'Hata!',
    info: 'Bilgi!',
    success: 'Başarılı',
    warning: 'Uyarı',
  },
  error: {
    plugin_method_not_avaliable: 'Tanımlı kontrol tipi için Eklenti methodu bulunamadı.',
    plugin_not_avaliable: 'Tanımlı kontrol tipi bulunamadı!',
    is_required: 'Doldurulması zorunlu alan! Lütfen boş bırakmayınız.',
    cannot_be_null: 'Geçersiz Veri Tipi! Bu alan NULL değerde olamaz.',
    number_max_exceed: 'Daha küçük bir sayı giriniz.',
    number_min_exceed: 'Daha büyük bir sayı giriniz.',
    length_max_exceed: 'Karakter sayısını aştınız!',
    length_min_exceed: 'Yetersiz Karakter Sayısı',
    invalid_regexp: 'Bu alan eşleşmemektedir.',
    invalid_int: 'Bu alan sadece sayı olabilir.',
    invalid_double: 'Bu alan sadece sayısal yada ondalık tipte veri alabilir.',
    invalid_email: 'Geçersiz e-mail adresi',
    invalid_boolean: 'Geçersiz veri tipi',
    invalid_url: 'Geçersiz URL adresi',
    invalid_domain: 'Geçersiz domain adresi',
    invalid_ip: 'Geçersiz IP adresi',
    invalid_ipv4: 'Geçersiz IP adresi. Sadece ipv4 tipinde olmalıdır.',
    invalid_ipv6: 'Geçersiz IP adresi. Sadece ipv6 tipinde olmalıdır.',
    invalid_mac: 'Geçersiz MAC adresi',
    invalid_weak_password: 'Geçersiz şifre.Şifrenizin içerisinde en az bir büyük harf, bir küçük harf, bir sayı ve özel bir karakter olmalıdır.',
    invalid_date: 'Geçersiz veri. Bu alan sadece tarih olabilir.',
    invalid_option: 'Geçersiz veri tipi',
    error_maxlength: 'Karakter sayısını aştınız!',
    error_minlength: 'Yetersiz Karakter Sayısı',
    error_required: 'Doldurulması Zorunlu Alan',
    error_invalidemail: 'Geçersiz E-mail Adresi',
    error_weakpassword: 'Zayıf Parola',
    error_isnumber: 'Sadece sayı giriniz!',
    error_minnumber: 'Bu alan %s sayıları arasında olmalı. Daha büyük bir sayı giriniz.',
    error_maxnumber: 'Bu alan %s sayıları arasında olmalı. Daha küçük bir sayı giriniz.',
    error_match: 'Bu alan eşleşmemektedir.',
    error_invalidurl: 'Geçersiz Url',
    error_invalidalpha: 'Geçersiz Alfabetik Karakter',
    error_invalidmac: 'Geçersiz MAC adresi',
    error_invalidip: 'Geçersiz IP adresi',
    error_invaliddomain: 'Geçersiz domain adresi',
    invalid_user: 'Kullanıcı bulunamadı.',
    invalid_username_password: 'Kullanıcı adı veya parola bulunamadı!',
    invalid_password: 'Parola hatalı.',
    usernamecombnotselected: 'Kullanıcı adı kombinasyonu boş bırakılamaz.',
    templatenotselected: 'Kullanıcı şablonu boş bırakılamaz.',
    authtypenotselected: 'Doğrulama yöntemi seçilmedi.',
    error_license_pms: 'Veritabanı entegrasyonunu kullanabilmek için lisans yükseltmeniz gerekmektedir.',
    invalid_oldpassword: 'Parolanızı değiştirebilmek için eski parolanızı doğru bir şekilde girmeniz gerekmektedir.',
    token_expired: 'İstek zaman aşımına uğradı.',
    license_expired: 'Lisansınızın süresi sona ermiştir.',
    smsprovidernotselected: 'SMS sağlayıcı seçilmedi',
    smspasswordexpinvalid: 'SMS parola süresi boş bırakılamaz.',
    mailerprovidernotselected: 'Mail sağlayıcı seçilmedi.',
    recipientrulesnotselected: 'En az bir kural eklenmelidir.',
    device: {
      device_limit_error: 'Cihaz ekleme limitine ulaştınız!',
    },
  },
  general: {
    selectall: 'Tümünü Seç',
    select: 'Seç',
    on: 'Açık',
    off: 'Kapalı',
    active: 'Aktif',
    deactive: 'Pasif',
    successful: 'Başarılı',
    unsuccessful: 'Başarısız',
    approve: 'Onayla',
    approved: 'Onaylandı',
    reject: 'Reddet',
    disapproved: 'Onaylanmadı',
    deleteconfirm: 'Silmek istediğinize emin misiniz?',
    save: 'Kaydet',
    edit: 'Düzenle',
    detail: 'Detay',
    delete: 'Sil',
    next: 'Sonraki',
    prev: 'Önceki',
    first: 'İlk',
    last: 'Son',
    all: 'Tümü',
    apply_filter: 'Filtrele',
    back: 'Geri Dön',
    selectplaceholder: 'Seçiniz...',
    choosefile: 'Dosya Seçiniz...',
    male: 'ERKEK',
    female: 'KADIN',
    other: 'DİĞER',
    unknown: 'BİLİNMEYEN',
    none: 'DEĞER YOK',
    pagination: {
      info: 'Toplam %s kayıttan %s-%s arası kayıtlar görüntüleniyor.',
      nodata: 'Kayıt bulunmamaktadır.',
    },
    advanced_filter: 'Gelişmiş Filtre',
    ssl: 'SSL',
    tls: 'TLS',
  },
  notification: {
    title: 'Bildirimler',
    warning: {
      left_sms: 'SMS hakkınız eşik değerine ulaştı.',
      left_session: 'Kullanım süresi hakkınız eşik değerine ulaştı.',
    },
    danger: {
      left_sms: 'SMS hakkınız kritik seviyede.',
      left_session: 'Kullanım süresi hakkınız kritik seviyede.',
    },
  },
};
