import { action, makeObservable, observable } from 'mobx';

class CaptivePortalStore {
  inputErrors = {};

  stepData = [];

  editData = {
    editId: null,
    name: '',
    settings: {
      eula: {
        status: 0, // 0 ->kapalı 1 ->opsiyonel 2->zorunlu
        text: 'Enter EULA',
      },
      nda: {
        status: 0, // 0 ->kapalı 1 ->opsiyonel 2->zorunlu
        text: 'Enter NDA',
      },
    },
    design: {
      position: 'center',
      background: {
        backgroundColor: 'rgb(0,0,0)',
        backgroundImage: '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      button: {
        type: 'block',
        backgroundColor: 'rgb(191,45,86)',
        color: 'rgb(255,255,255)',
        fontSize: '16',
        borderRadius: '10',
        border: '0',
        borderColor: 'rgba(191,45,86,0.5)',
        padding: '10',
        margin: '40',
      },
      inputs: {
        label: {
          color: 'rgb(0,0,0)',
          fontSize: '12',
        },
        input: {
          type: 'block',
          backgroundColor: 'rgb(255,255,255)',
          color: 'rgb(0,0,0)',
          fontSize: '12',
          borderRadius: '5',
          border: '1',
          borderColor: 'rgba(191,45,86,0.5)',
          padding: '5',
          margin: '10',
        },
      },
      logo: {
        url: '',
      },
    },
    loginOptions: [{
      authType: [],
      askedFields: [],
      labels: {},
    }],
    labels: [],
    devices: [],

  };

  listData = [];

  selectedId = null;

  constructor() {
    makeObservable(this, {
      inputErrors: observable,
      stepData: observable,
      editData: observable,
      listData: observable,
      selectedId: observable,
      setInputErrors: action,
      setStepData: action,
      setStepStatus: action,
      getStepStatus: action,
      setStepOnchange: action,
      setDataDesign: action,
      getDataDesign: action,
      setDataLoginOptions: action,
      getDataLoginOptions: action,
      setDataLabels: action,
      getDataLabels: action,
      getDataName: action,
      setDataName: action,
      getDataDevices: action,
    });
  }

  setInputErrors(errors) {
    this.inputErrors = errors;
  }

  setStepData(data) {
    this.stepData = [];
    data.map((element) => this.stepData.push({
      name: element.name,
      icon: element.icon,
      status: false,
      onchange: false,
      component: element.component,
    }));
    if (this.stepData.length > 0) {
      this.stepData[0].onchange = true;
    }
  }

  setStepStatus(key, status) {
    this.stepData[key].status = status;
  }

  getStepStatus(key) {
    return this.stepData[key].status;
  }

  setStepOnchange(key) {
    this.stepData.forEach((e, i) => {
      if (key === i) {
        this.stepData[i].onchange = true;
      } else {
        this.stepData[i].onchange = false;
      }
    });
  }

  setDataDesign(data) {
    this.editData.design = data;
  }

  getDataDesign() {
    return this.editData.design;
  }

  setDataLoginOptions(data) {
    this.editData.loginOptions = data;
  }

  getDataLoginOptions() {
    return this.editData.loginOptions;
  }

  setDataLabels(data) {
    this.editData.labels = data;
  }

  getDataLabels() {
    return this.editData.labels;
  }

  getDataName() {
    return this.editData.name;
  }

  setDataName(data) {
    this.editData.name = data;
  }

  getDataDevices() {
    return this.editData.devices;
  }
}

export default new CaptivePortalStore();
