/* eslint-disable global-require */
import React, { Suspense, lazy } from 'react';
import { Col } from 'reactstrap';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
// import Register from '../../Components/Register/Register';
import translate from '../../Services/TranslateService';
import './auth.css';
import Logo from '../../Assets/images/logo.png';
import Loading from '../../Components/Loading/Loading';

const Login = lazy(() => import('../../Components/Login/Login'));
const ForgotPassword = lazy(() =>
  import('../../Components/Login/ForgotPassword')
);
const RecoverPassword = lazy(() =>
  import('../../Components/Login/RecoverPassword')
);
const AutoLogin = lazy(() => import('../../Components/Login/AutoLogin'));

const Auth = () => (
  <Suspense fallback={<Loading status={false} />}>
    <div className="container-fluid">
      <div className="row equal">
        <Col className="col-md-4 col-sm-6 col-12 padding-40 login-left">
          <div className="auth-logo h-20">
            <img src={Logo} alt="Wirofy" />
            <h1>{translate.getText('auth.title')}</h1>
            <h2>{translate.getText('auth.subtitle')}</h2>
          </div>
          <div className="h-80 auth-center">
            <Router>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route
                  path="/recoverpassword/:hash"
                  component={RecoverPassword}
                />
                <Route path="/auto_login/:hash" component={AutoLogin} />
                <Route path="/autologin/:hash" component={AutoLogin} />
                <Route exact path="*" render={() => <Redirect to="/login" />} />
              </Switch>
            </Router>
          </div>
        </Col>
        <Col className="col-md-8 col-sm-6 col-0 padding-40 login-right">
          <img
            className="main-image"
            src={require('../../Assets/images/main.png')}
            alt=""
          />
        </Col>
      </div>
    </div>
  </Suspense>
);

export default Auth;
